import { render, staticRenderFns } from "./proceedingsResultAppr.vue?vue&type=template&id=bf8ee718"
import script from "./proceedingsResultAppr.vue?vue&type=script&lang=js"
export * from "./proceedingsResultAppr.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\NewDevelopment\\PROJECT\\KNCO\\FrontEnd\\KNCOFrontEnd\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bf8ee718')) {
      api.createRecord('bf8ee718', component.options)
    } else {
      api.reload('bf8ee718', component.options)
    }
    module.hot.accept("./proceedingsResultAppr.vue?vue&type=template&id=bf8ee718", function () {
      api.rerender('bf8ee718', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/appr/sai/osh/proceedingsResultAppr.vue"
export default component.exports